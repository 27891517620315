import type { Pathnames } from 'next-intl/navigation'

import { browserEnvs } from './envs'
import { getAvailableLocales } from './locale'

export const LOGGED_IN_ROOT_ROUTE = '/my'
export const LOGGED_IN_ROOT_ROUTE_INTL = {
  en: '/loggedin',
  sv: '/inloggad',
  fi: '/loggedin',
  nb: '/loggedin',
} as const

export const routes = {
  HOME: '/',
  THANK_YOU: '/thank-you',
  UPGRADE_FLOW: '/upgrade-flow',
  CHECKOUT: '/checkout',
  BLOG: '/blog',
  BLOG_CATEGORY: '/blog/[slug]/[page]',
  BLOG_ARTICLE: '/blog/[slug]',
  FAQ: '/faq',
  SERVICE_BREAK: '/service-break/[client]',
  LOG_IN: '/login',

  ENTERPRISE_HOME: '/enterprise',
  ENTERPRISE_SLUG: '/enterprise/[...slug]',
  ENTERPRISE_THANK_YOU: '/enterprise/thank-you',
  ENTERPRISE_UPGRADE_FLOW: '/enterprise/upgrade-flow',
  ENTERPRISE_CHECKOUT: '/enterprise/checkout',
  ENTERPRISE_BLOG: '/enterprise/blog',
  ENTERPRISE_BLOG_CATEGORY: '/enterprise/blog/[slug]/[page]',
  ENTERPRISE_BLOG_ARTICLE: '/enterprise/blog/[slug]',
  ENTERPRISE_FAQ: '/enterprise/faq',

  LOGGED_IN_ROOT_ROUTE,
  LOGGED_IN_OVERVIEW: `${LOGGED_IN_ROOT_ROUTE}/overview`,
  LOGGED_IN_CONTRACTS: `${LOGGED_IN_ROOT_ROUTE}/contracts`,
  LOGGED_IN_ELECTRICITY: `${LOGGED_IN_ROOT_ROUTE}/electricity`,
  LOGGED_IN_INVOICES: `${LOGGED_IN_ROOT_ROUTE}/invoices`,
  LOGGED_IN_SETTINGS: `${LOGGED_IN_ROOT_ROUTE}/settings`,

  QUIZ: '/quiz/[slug]',
} as const

const country = browserEnvs.NEXT_PUBLIC_COUNTRY
const availableLocales = getAvailableLocales(country)

type GeneratedPathnames = Record<
  string | (typeof routes)[keyof typeof routes],
  { [Key in (typeof availableLocales)[number]]: string } | string
>

export const pathnames: GeneratedPathnames = {
  // B2C routes
  [routes.HOME]: routes.HOME,
  [routes.UPGRADE_FLOW]: {
    en: routes.UPGRADE_FLOW,
    sv: `${routes.HOME}anpassa-elavtal`,
    fi: `${routes.HOME}muokkaa-sahkosopimusta`,
    nb: `${routes.HOME}tilpass-stromavtale`,
  },
  [routes.CHECKOUT]: {
    en: routes.CHECKOUT,
    sv: `${routes.HOME}bestallning`,
    fi: `${routes.HOME}tilaus`,
    nb: `${routes.HOME}bestill`,
  },
  [routes.THANK_YOU]: {
    en: routes.THANK_YOU,
    sv: `${routes.HOME}tack`,
    fi: `${routes.HOME}kiitos`,
    nb: `${routes.HOME}takk`,
  },
  [routes.BLOG]: {
    en: routes.BLOG,
    sv: `${routes.HOME}radgivning/blogg`,
    fi: routes.BLOG,
    nb: routes.BLOG,
  },
  [routes.BLOG_CATEGORY]: {
    en: routes.BLOG_CATEGORY,
    sv: `${routes.HOME}radgivning/blogg/[slug]/[page]`,
    fi: routes.BLOG_CATEGORY,
    nb: routes.BLOG_CATEGORY,
  },
  [routes.BLOG_ARTICLE]: {
    en: routes.BLOG_ARTICLE,
    sv: `${routes.HOME}radgivning/blogg/[slug]`,
    fi: routes.BLOG_ARTICLE,
    nb: routes.BLOG_ARTICLE,
  },
  [routes.FAQ]: {
    en: routes.FAQ,
    sv: `${routes.HOME}kundservice/fragor-och-svar`,
    nb: routes.FAQ,
    fi: routes.FAQ,
  },
  [routes.LOG_IN]: {
    en: routes.FAQ,
    sv: `${routes.HOME}loggain`,
    nb: routes.FAQ,
    fi: routes.FAQ,
  },
  // Enterprise routes
  [routes.ENTERPRISE_HOME]: {
    en: routes.ENTERPRISE_HOME,
    sv: `${routes.HOME}foretag`,
    fi: routes.ENTERPRISE_HOME,
    nb: routes.ENTERPRISE_HOME,
  },
  [routes.ENTERPRISE_SLUG]: {
    en: routes.ENTERPRISE_SLUG,
    sv: `${routes.HOME}foretag/[...slug]`,
    fi: routes.ENTERPRISE_SLUG,
    nb: routes.ENTERPRISE_SLUG,
  },
  [routes.ENTERPRISE_UPGRADE_FLOW]: {
    en: routes.ENTERPRISE_UPGRADE_FLOW,
    sv: `${routes.HOME}foretag/anpassa-elavtal`,
    fi: `${routes.ENTERPRISE_HOME}muokkaa-sahkosopimusta`,
    nb: `${routes.ENTERPRISE_HOME}tilpass-stromavtale`,
  },
  [routes.ENTERPRISE_CHECKOUT]: {
    en: routes.ENTERPRISE_CHECKOUT,
    sv: `${routes.HOME}foretag/bestallning`,
    fi: `${routes.ENTERPRISE_HOME}tilaus`,
    nb: `${routes.ENTERPRISE_HOME}bestill`,
  },
  [routes.ENTERPRISE_THANK_YOU]: {
    en: routes.ENTERPRISE_THANK_YOU,
    sv: `${routes.HOME}foretag/tack`,
    fi: `${routes.HOME}kiitos`,
    nb: `${routes.HOME}takk`,
  },
  [routes.ENTERPRISE_BLOG]: {
    en: routes.ENTERPRISE_BLOG,
    sv: `${routes.HOME}foretag/radgivning/blogg`,
    fi: routes.ENTERPRISE_BLOG,
    nb: routes.ENTERPRISE_BLOG,
  },
  [routes.ENTERPRISE_BLOG_CATEGORY]: {
    en: routes.ENTERPRISE_BLOG_CATEGORY,
    sv: `${routes.HOME}foretag/radgivning/blogg/[slug]/[page]`,
    fi: routes.ENTERPRISE_BLOG_CATEGORY,
    nb: routes.ENTERPRISE_BLOG_CATEGORY,
  },
  [routes.ENTERPRISE_BLOG_ARTICLE]: {
    en: routes.ENTERPRISE_BLOG_ARTICLE,
    sv: `${routes.HOME}foretag/radgivning/blogg/[slug]`,
    fi: routes.ENTERPRISE_BLOG_ARTICLE,
    nb: routes.ENTERPRISE_BLOG_ARTICLE,
  },
  [routes.ENTERPRISE_FAQ]: {
    en: routes.ENTERPRISE_FAQ,
    sv: `${routes.HOME}foretag/kundservice/fragor-och-svar`,
    nb: routes.ENTERPRISE_FAQ,
    fi: routes.ENTERPRISE_FAQ,
  },
  // Logged in routes
  [routes.LOGGED_IN_OVERVIEW]: {
    en: routes.LOGGED_IN_OVERVIEW,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/oversikt`,
    fi: routes.LOGGED_IN_OVERVIEW,
    nb: routes.LOGGED_IN_OVERVIEW,
  },
  [routes.LOGGED_IN_ELECTRICITY]: {
    en: routes.LOGGED_IN_ELECTRICITY,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/el`,
    fi: routes.LOGGED_IN_ELECTRICITY,
    nb: routes.LOGGED_IN_ELECTRICITY,
  },
  [routes.LOGGED_IN_CONTRACTS]: {
    en: routes.LOGGED_IN_CONTRACTS,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/avtal`,
    fi: routes.LOGGED_IN_CONTRACTS,
    nb: routes.LOGGED_IN_CONTRACTS,
  },
  [routes.LOGGED_IN_INVOICES]: {
    en: routes.LOGGED_IN_INVOICES,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/fakturor`,
    fi: routes.LOGGED_IN_INVOICES,
    nb: routes.LOGGED_IN_INVOICES,
  },
  [routes.LOGGED_IN_SETTINGS]: {
    en: routes.LOGGED_IN_SETTINGS,
    sv: `${LOGGED_IN_ROOT_ROUTE_INTL['sv']}/installningar`,
    fi: routes.LOGGED_IN_SETTINGS,
    nb: routes.LOGGED_IN_SETTINGS,
  },
  [routes.SERVICE_BREAK]: {
    en: routes.SERVICE_BREAK,
    sv: '/service-avbrott/[client]',
    nb: routes.SERVICE_BREAK,
    fi: routes.SERVICE_BREAK,
  },

  [routes.QUIZ]: {
    en: routes.QUIZ,
    sv: routes.QUIZ,
    nb: routes.QUIZ,
    fi: routes.QUIZ,
  },
  // Generic routes
  '[route]': '[route]',
} satisfies Pathnames<typeof availableLocales>

export const serviceBreakClient = {
  LOG_IN: 'log-in',
  PURCHASE_FLOW: 'purchase-flow',
}
